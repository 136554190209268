import * as React from "react";
import { List, Create, Datagrid, FileField, FileInput, SimpleForm, TextField, NumberField, UrlField, Show, SimpleShowLayout, DateField, SelectInput, SelectField  } from 'react-admin';
// import Box from '@material-ui/core/Box';
// import Card from '@material-ui/core/Card'
// import Typography from '@material-ui/core/Typography';
// import { Box, Card, CardContent, Typography } from '@material-ui/core';

/*
    Firstname string     `json:"firstname" example:"Vasile"`
    Lastname  string     `json:"lastname" example:"Lupu"`
    Phone     string     `json:"phone" example:"079123456"`
    Email     string     `json:"email,omitempty" example:"vasile.lupu@mail.md"`
    IsMale    bool       `json:"is_male,omitempty" example:"true"`
    Birhtdate *time.Time `json:"birthdate,omitempty" example:"1996-01-02T00:00:00Z"`
    Barcode   string     `json:"barcode,omitempty"`   // ignored when calling card registration
    Signature string     `json:"signature,omitempty"` // base64 encoded jpeg image
*/



// const Empty = ({ basePath, resource }) => (
//     <Box textAlign="left" m={1}>
//         <Card>
//             <CardContent>
//                 <Typography variant="body2">
//                     No results found
//                 </Typography>
//             </CardContent>
//         </Card>
//     </Box>
// );


export const RegistryList = (props) => (
    <List  {...props} title='Registry' sort={{ field: 'id', order: 'DESC' }}>
        <Datagrid>
            <NumberField source="id" label="ID" />
            <DateField showTime={true} source="created_at" label="Date" />
            <SelectField source="order_type" choices={[
                { id: 1, name: 'Amended' },
                { id: 2, name: 'Result' },
            ]} />
            <TextField source="xml_file_name" label="RegistryXml" />
            <UrlField source="result_field" label="Result.zip" />
        </Datagrid>
    </List>
);
export const RegistryShow = (props) => (
    <Show {...props} title='Registry'>
        <SimpleShowLayout>
            {/* <TextField source="id" label="ID" /> */}
            <NumberField source="id" label="ID" />
            {/* <NumberField source="user_id" label="UserID" /> */}
            <TextField source="xml_file_name" label="RegistryXml" />
            <UrlField source="result_field" label="Result" />
        </SimpleShowLayout>
    </Show>
);


export const RegistryCreate = (props) => (
    <Create {...props} title='Registry'>
        <SimpleForm>
            <SelectInput  source="order_type" label="Order Type" initialValue='1' choices={[
                { id: '1', name: 'Amended' },
                { id: '2', name: 'Result' },
            ]} />
            <FileInput source="xml" label="Registry XML" accept="text/xml">
                <FileField source="file" title="title"/>
            </FileInput>
        </SimpleForm>
    </Create>
);