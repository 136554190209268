import * as React from "react";
import { Admin, Resource } from 'react-admin';
// import { ListGuesser } from 'react-admin';

import dataProvider from './dataProvider';
import authProvider from './authProvider';
import { UserList, UserShow, UserEdit, UserCreate } from './users';
import { EgaisList, EgaisCreate } from './egais';
import { RegistryList, RegistryCreate } from './registry';
import { MeridianList, MeridianCreate, MeridianShow } from './meridian';
import { PalletList, PalletShow } from './pallet';
import { BoxList, BoxShow } from './box';
// import { MarkList } from './mark';
import { BottleList, BottleShow } from './bottle';
import { LogList, LogShow } from './logs';
// import { MyDashboard } from "./Dashboard";
import UserIcon from '@material-ui/icons/Group';
import LocalBarIcon from '@material-ui/icons/LocalBar';
import BorderAllIcon from '@material-ui/icons/BorderAll';
import LanguageIcon from '@material-ui/icons/Language';
import StorageIcon from '@material-ui/icons/Storage';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import LineWeightIcon from '@material-ui/icons/LineWeight';

import polyglotI18nProvider from 'ra-i18n-polyglot'; // install the package
import englishMessages from 'ra-language-english'; // install the package

const i18nProvider = polyglotI18nProvider(() => englishMessages, 'en', { allowMissing: true });

const App = () => (
    <Admin disableTelemetry i18nProvider={i18nProvider} dataProvider={dataProvider} authProvider={authProvider} >
        {permissions => [
            // permissions === 'admin'
            //     ?
            //     <MyDashboard />
            //     : null,
            permissions === 'admin'
                ?
                <Resource name="users" options={{ label: 'Users' }} list={UserList} show={UserShow} edit={UserEdit} create={UserCreate} icon={UserIcon} />
                : null,
            permissions === 'admin'
                ?
                <Resource name="logs" options={{ label: 'Logs' }} list={LogList} show={LogShow} icon={RecentActorsIcon} />
                : null,
            <Resource name="egais" options={{ label: 'EGAIS' }} list={EgaisList} create={EgaisCreate} icon={LocalBarIcon} />,
            <Resource name="registry" options={{ label: 'Marks' }} list={RegistryList} create={RegistryCreate} icon={LocalBarIcon} />,
            // <Resource name="meridian" options={{ label: 'Meridian' }} list={MeridianList} show={MeridianShow} create={MeridianCreate} icon={LanguageIcon} />,
            // <Resource name="pallet" options={{ label: 'Pallet' }} list={PalletList} show={PalletShow} icon={StorageIcon} />,
            // <Resource name="box" options={{ label: 'Box' }} list={BoxList} show={BoxShow} icon={BorderAllIcon} />,
            // <Resource name="bottle" options={{ label: 'Bottle' }} list={BottleList} show={BottleShow} icon={LineWeightIcon} />,
            // <Resource name="mark" options={{ label: 'Mark' }} list={MarkList} icon={LineWeightIcon} />,
        ]}
    </Admin>
);
export default App;